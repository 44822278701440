import Image from 'next/image';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import headerInputBg from './bg-input-header.png';
import bodyInputBg from './bg-input-body.png';
import footerInputBg from './bg-input-footer.png';
import headerInputBoldBg from './bg-input-header-bold.png';
import bodyInputBoldBg from './bg-input-body-bold.png';
import footerInputBoldBg from './bg-input-footer-bold.png';

export type InputProps = JSX.IntrinsicElements['input'] & {
  id?: string;
  wrapClassName?: string;
  inputWrapClassName?: string;
  labelClassName?: string;
  prefixClassName?: string;
  suffixClassName?: string;
  className?: string;
  errorClassName?: string;
  helpClassName?: string;
  inputPrefix?: React.ReactNode;
  inputSuffix?: React.ReactNode;
  label?: string;
  helpText?: string;
  error?: string | React.ReactNode;
  bold?: boolean;
  maxLength?: number;
};

export default forwardRef<HTMLInputElement, InputProps>(function ModalInput(props, ref) {
  const [currentLength, setCurrentLength] = React.useState(0);
  const {
    id,
    wrapClassName,
    inputWrapClassName,
    labelClassName,
    prefixClassName,
    suffixClassName,
    className,
    errorClassName,
    helpClassName,
    inputPrefix,
    inputSuffix,
    label,
    helpText,
    error,
    bold,
    maxLength,
    defaultValue,
    onChange,
    ...rest
  } = props;

  return (
    <div className={wrapClassName}>
      <div
        className={twMerge(
          'relative flex w-full h-full justify-between items-center px-5 sm:px-7 2xl:px-10 py-5 z-[2] max-h-[60px]',
          inputWrapClassName,
        )}
      >
        {inputPrefix && (
          <div
            className={twMerge(
              'flex justify-center items-center min-w-5 text-sm mr-1 sm:mr-2 z-[2]',
              prefixClassName,
            )}
          >
            {inputPrefix}
          </div>
        )}
        <div className="flex flex-col w-full min-w-0 relative z-[2]">
          {label && (
            <label
              className={twMerge('text-[#7BFAFC] xl:text-[12px] text-[10px]', labelClassName)}
              htmlFor={id}
            >
              {label}
            </label>
          )}
          <input
            {...rest}
            ref={ref}
            id={id}
            maxLength={maxLength}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              setCurrentLength(e.target.value.length);
            }}
            autoComplete="off"
            className={twMerge(
              'w-full bg-transparent text-one-line focus:outline-none text-[12px] xl:text-[16px] font-semibold placeholder:text-[#abb0b0] autofill:bg-transparent',
              'autofill:[-webkit-text-fill-color:_#fff]',
              className,
            )}
          />
        </div>
        {maxLength && (
          <>
            <div className="w-[40px]" />
            <div className="text-white flex items-center absolute right-6 top-1/2 -translate-y-1/2 z-20">
              <p className="text-base font-semibold">
                {currentLength || defaultValue?.toString()?.length}/{maxLength}
              </p>
            </div>
          </>
        )}
        {inputSuffix && (
          <div
            className={twMerge(
              'min-w-5 flex justify-center items-center text-sm z-[2]',
              suffixClassName,
            )}
          >
            {inputSuffix}
          </div>
        )}
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="relative h-full w-full flex">
            <Image
              src={bold ? headerInputBoldBg : headerInputBg}
              alt=""
              className="h-full w-auto"
            />
            <Image
              src={bold ? bodyInputBoldBg : bodyInputBg}
              alt=""
              className="h-full w-full min-w-0"
            />
            <Image
              src={bold ? footerInputBoldBg : footerInputBg}
              alt=""
              className="h-full w-auto"
            />
          </div>
        </div>
      </div>
      {error && (
        <p
          className={twMerge(
            'text-[#FE2E2E] text-sm mt-2 px-8 text-left error-message',
            errorClassName,
          )}
        >
          {error}
        </p>
      )}
      {helpText && !error && (
        <p className={twMerge('text-white opacity-[0.5] text-sm mt-2 px-8', helpClassName)}>
          {helpText}
        </p>
      )}
    </div>
  );
});

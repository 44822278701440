import Image, { StaticImageData } from 'next/image';
import React, { ChangeEvent, forwardRef, ReactNode, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { BgImage } from '@flyer/components/next';
import { toast } from '@/../../../libs/components/src';
import useTranslation from 'next-translate/useTranslation';
import defaultAvatar from '@/assets/images/default-avatar.png';

type ModalAvatarProps = JSX.IntrinsicElements['div'] & {
  src?: string | StaticImageData | null;
  className?: string;
  wrapClassName?: string;
  iconClassName?: string;
  wrapIconClassName?: string;
  alt: string;
  icon?: StaticImageData;
  children?: ReactNode;
  width?: number;
  height?: number;
  iconPosition?: keyof typeof ICON_POSITION;
  canUpload?: boolean;
  onUpload?: (file: File | undefined) => void;
  maxSizeMb?: number;
};

export const ICON_POSITION = {
  'top-right': 'top-0 right-0',
  'bottom-center': 'bottom-[-15px] left-[50%] translate-x-[-50%]',
  'top-center': 'top-[50%]  right-[-10%] translate-y-[-50%]',
};

export default forwardRef<HTMLDivElement, ModalAvatarProps>(function ModalAvatar(
  {
    src,
    className,
    wrapClassName,
    iconClassName,
    wrapIconClassName,
    alt,
    icon,
    children,
    width,
    height,
    iconPosition = 'top-right',
    canUpload = false,
    maxSizeMb = 5,
    onUpload,
  },
  ref,
) {
  const { t } = useTranslation();
  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      const maxSize = maxSizeMb * 1024 * 1024;
      if (e.target.files[0] && e.target.files[0].size > maxSize) {
        toast.error(
          t('error:label.max_file_size', {
            size: maxSizeMb,
          }),
        );
        return;
      }
      if (onUpload) {
        onUpload(e.target.files[0]);
      }
    },
    [maxSizeMb, onUpload, t],
  );

  return (
    <div
      ref={ref}
      className={twMerge(
        'rounded-[50%] transition-colors p-[5px] border-solid border-[2px] border-[#8000FF] bg-[#4C359F] relative shadow-[0px 0px 30px rgba(0, 0, 0, 0.35)] hover:bg-[#98CBFF]',
        wrapClassName,
      )}
    >
      {icon && (
        <div className={twMerge(`absolute ${ICON_POSITION[iconPosition]}`, wrapIconClassName)}>
          {canUpload ? (
            <label className="cursor-pointer">
              <BgImage src={icon} alt="Add avatar" imageClassName={iconClassName}>
                <input
                  name="avatarFile"
                  type="file"
                  hidden
                  autoComplete="off"
                  multiple={false}
                  onChange={handleAvatarChange}
                  accept="image/png, image/jpeg, image/jpg"
                />
              </BgImage>
            </label>
          ) : (
            <Image src={icon} alt="Avatar icon" className={iconClassName} />
          )}
        </div>
      )}
      {children || (
        <Image
          src={src || defaultAvatar}
          alt={alt}
          className={twMerge(
            'rounded-[inherit] object-cover w-full h-full aspect-square',
            className,
          )}
          width={width}
          height={height}
        />
      )}
    </div>
  );
});
